
import { Vue, Component, Prop } from 'vue-property-decorator'

import $app from '@/plugins/modules'
import { Common } from '@/common'
import { IViewAction } from '@/modules/system/types'

@Component
export default class BaseView extends Vue {
  @Prop(String)
  readonly caption: string | undefined;

  @Prop({ default: false })
  readonly loading!: boolean;

  @Prop({ default: false })
  readonly darkTitle!: boolean | string;

  @Prop({ default: false })
  readonly fluid!: boolean | string;

  @Prop({ default: false })
  readonly hideTitle!: boolean | string;

  @Prop({ default: false })
  readonly hideTitleDivider!: boolean | string;

  @Prop({ default: () => [] })
  readonly actions!: Array<IViewAction>;

  @Prop([String, Number])
  readonly height: string | number | undefined;

  common = $app.module(Common);

  updateEnv() {
    this.common.$store.appSetViewTitle(this.caption || '');
  }

  onScroll(e: any) {
    this.$emit('scroll', e);
  }

  updated() {
    this.updateEnv();
  }

  created() {
    this.updateEnv();
  }
}
